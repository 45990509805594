import React, { useState, useEffect } from "react"
import "table.css"
import {
  Coin,
  Coins,
  CreateTxOptions,
  Fee,
  MsgSend,
  SignerInfo,
  int,
} from "@terra-money/terra.js"
import {
  MsgExecuteContract,
  MsgInstantiateContract,
} from "@terra-money/terra.js"
import { TxResult, useWallet } from "@terra-money/wallet-provider"
import { useNetwork, useContract, useAddress, useConnectModal } from "hooks"
import { useLCDClient } from "layouts/WalletConnectProvider"
import useGasPrice from "rest/useGasPrice"
import styled from "styled-components"
import Container from "components/Container"
import Result from "forms/Result"
import styles from "components/Modal.module.scss"
import { useModal } from "components/Modal"
import {
  CreateTxFailed,
  TxFailed,
  TxUnspecifiedError,
  UserDenied,
} from "@terra-dev/wallet-types"
import { number } from "libs/math"
import Button from "components/Button"

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
`

const ARBITER_ADDRESS = "terra1jx5ymd6kw07a8yeswnr8fkd8zzz8naccft2r7q"
const ESCROW_CONTRACT =
  "terra130nqwcdj778vukrnhhd3t7ed3rw7lwdtfy8lzw5a806nr6t6xm9q7cqfmr"

function TerraClassicEscrows() {
  const wallet = useWallet()
  const walletAddress = useAddress()
  const connectModal = useConnectModal()
  const { terra } = useLCDClient()
  const { gasPrice } = useGasPrice("uluna")
  const [contractAddress, setContractAddress] = useState()
  const [escrowIds, setEscrowIds] = useState([])
  const [escrowDetails, setEscrowDetails] = useState([])
  const [escrowsToRelease, setEscrowsToRelease] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [result, setResult] = useState()
  const [isResultModalOpen, setIsResultModalOpen] = useState(false)

  useEffect(() => {
    // Fetch data from the first URL to get escrow IDs
    fetch(
      `https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/${ESCROW_CONTRACT}/smart/eyJsaXN0Ijp7fX0=`
    )
      .then((response) => response.json())
      .then((data) => {
        setEscrowIds(data.data.escrows)
      })
      .catch((error) => {
        console.error("Error fetching escrow IDs:", error)
      })
  }, [])

  useEffect(() => {
    // Fetch escrow details for each ID and store them in escrowDetails array
    const fetchEscrowDetails = async () => {
      setIsLoading(true) // Set loading to true while fetching data
      const detailsPromises = escrowIds.map(async (id) => {
        const encodedId = btoa(JSON.stringify({ details: { id } }))
        const url = `https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/${ESCROW_CONTRACT}/smart/${encodedId}`

        try {
          const response = await fetch(url)
          const data = await response.json()
          return data.data
        } catch (error) {
          console.error(`Error fetching escrow details for ID ${id}:`, error)
          return null
        }
      })

      const details = await Promise.all(detailsPromises)
      setEscrowDetails(details)
      setIsLoading(false) // Set loading to false after data is fetched
    }

    if (escrowIds.length > 0) {
      fetchEscrowDetails()
    }
  }, [escrowIds])

  useEffect(() => {
    if (walletAddress && !isLoading) {
      const escrows = escrowDetails
        .filter((escrow) => {
          return escrow.arbiter === walletAddress && escrow.end_time < Math.floor(Date.now() / 1000);
          return (
            escrow.arbiter === ARBITER_ADDRESS &&
            escrow.end_time < Math.floor(Date.now() / 1000) + 90 * 24 * 60 * 60 //(for test w/in 90 days)
          )
        })
        .map((escrow) => escrow.id)
      setEscrowsToRelease(escrows)
    }
  }, [walletAddress, isLoading, escrowDetails])

  const handleReleaseButtonClick = () => {
    if (walletAddress) {
      try {
        const executeMsgs = escrowsToRelease.map((escrowId) => ({
          release: { id: escrowId },
        }))

        let msgs = []
        let memo = "Escrow Release"

        executeMsgs.forEach((executeMsg) => {
          const msg = new MsgExecuteContract(
            walletAddress,
            ESCROW_CONTRACT,
            executeMsg
          )
          msgs.push(msg)
        })

        let fee = new Fee(1000000, new Coins([new Coin("uluna", "1000000")]))

        let txOptions = {
          msgs,
          memo,
          gasPrices: `${gasPrice}${"uluna"}`,
          gasAdjustment: "3.0",
          feeDenoms: ["uluna"],
          fee: fee,
        }

        // Step 1: Perform the simulation
        let simulationGasResult = new Fee(
          3000000,
          new Coins([new Coin("uluna", "100000000")])
        )

        // Step 2: Calculate the chain tax based on the LUNC amount sent
        const luncAmountSent = getTotalLuncAmount(msgs)
        const chainTax = Math.floor(luncAmountSent * 0.0051) // 0.51% chain tax
        console.log("Chain Tax Result: ", chainTax)

        // Step 3: Add the chain tax and any additional LUNC fee to the simulated fee amount
        const additionalLuncFee = 20 * 1e6 // 20 LUNC in micro LUNC
        const totalFeeAmount = simulationGasResult.amount
          .get("uluna")
          .amount.add(Math.ceil(chainTax))
          .add(additionalLuncFee)
        console.log("Additional fee for padding: ", additionalLuncFee)

        // Step 4: Create the final Fee object with the total amount
        const finalFee = new Fee(
          simulationGasResult.gas_limit,
          new Coins([new Coin("uluna", totalFeeAmount.toString())])
        )
        console.log("Final Fee Result :", finalFee)

        // Use this final fee in your transaction options
        txOptions = {
          ...txOptions,
          fee: finalFee,
        }

        try {
          terra.tx
            .create([{ address: walletAddress }], txOptions)
            .then((signMsg) => {
              return wallet.post(txOptions, walletAddress)
            })
            .then((extensionResult) => {
              if (extensionResult) {
                setResult(extensionResult)
                setIsResultModalOpen(true)
              }
            })
            .catch((error) => {
              console.error(error)
              const errorMessage =
                error.message || "An error occurred during the transaction"
              setResult(error)
              setIsResultModalOpen(true)
            })
        } catch (error) {
          console.error("Error creating transaction:", error)
          setResult(error)
          setIsResultModalOpen(true)
        }
      } catch (error) {
        console.error(error)
        setResult(error)
      }
    } else {
      connectModal.open()
    }
  }

  // Function to calculate the total LUNC amount being sent
  function getTotalLuncAmount(msgs) {
    let totalLuncAmount = 0

    for (const msg of msgs) {
      // Check if `coins` exists and is not undefined
      if (msg.coins && typeof msg.coins.get === "function") {
        const coin = msg.coins.get("uluna")
        if (coin) {
          totalLuncAmount += parseInt(coin.amount.toString(), 10)
        }
      }
    }

    return totalLuncAmount
  }

  const handleFailure = () => {
    setTimeout(() => {}, 125)
    //setResult("There was a problem. Please try again later.")
    window.location.reload()
  }

  function unixTimestampToDDMMMYY(unixTimestamp) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ]

    const date = new Date(unixTimestamp * 1000) // Convert to milliseconds
    const dd = String(date.getDate()).padStart(2, "0") // Day
    const mmm = months[date.getMonth()] // Three-letter month abbreviation
    const yy = String(date.getFullYear()).slice(-2) // Last two digits of the year

    return `${dd} ${mmm} ${yy}`
  }

  function addCommasToNumber(number) {
    const parts = number.toString().split(".")
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    return parts.join(".")
  }

  function calculateCompletionPercentage(startTime, endTime) {
    const currentDate = new Date()
    const startDate = new Date(startTime * 1000) // Convert to milliseconds
    const endDate = new Date(endTime * 1000)
    const totalDuration = endDate - startDate
    const elapsedDuration = currentDate - startDate

    if (currentDate >= endDate) {
      return 100 // Return 100% if the current date is past the end date
    } else {
      return Math.min(Math.round((elapsedDuration / totalDuration) * 100), 100)
    }
  }

  // Render the table using escrowDetails data
  return (
    <Wrapper>
      {isResultModalOpen && (
        <div className="modal-overlay">
          <Container sm>
            <Result
              response={result}
              error={
                (result instanceof Error && result) || result === "User Denied"
                  ? result
                  : undefined
              }
              parserKey={"default"}
              onFailure={handleFailure}
            />
          </Container>
        </div>
      )}

      <div>
        <h1 className="padded-header">LUNW Staking List</h1>
        <h2 className="padded-header">
          {walletAddress && escrowsToRelease.length > 0 && (
            <Button onClick={handleReleaseButtonClick}>Release</Button>
          )}
        </h2>
        <div className="table-container">
          {isLoading ? (
            // Display loading message when data is being fetched
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "10vh",
              }}
            >
              <p style={{ fontSize: "2em", fontWeight: "bold" }}>Loading...</p>
            </div>
          ) : (
            <div className="table-wrapper">
              <table className="table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Recipient</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Staked Amount</th>
                    <th>Current Balance</th>
                    <th>End Balance</th>
                    <th>APY</th>
                    <th>Complete</th>
                  </tr>
                </thead>
                <tbody>
                  {escrowDetails
                    .filter((details) => parseInt(details.id, 10) >= 1000)
                    .map((details, index) => (
                      <tr key={index}>
                        <td>{details.id}</td>
                        <td>
                          {details.recipient.slice(0, 7) +
                            "..." +
                            details.recipient.slice(-4)}
                        </td>
                        <td>{unixTimestampToDDMMMYY(details.start_time)}</td>
                        <td>{unixTimestampToDDMMMYY(details.end_time)}</td>
                        <td>
                          {addCommasToNumber(details.principal / 1000000)}
                        </td>
                        <td>
                          {addCommasToNumber(details.current_balance / 1000000)}
                        </td>
                        <td>
                          {addCommasToNumber(details.mature_balance / 1000000)}
                        </td>
                        <td>{details.apr + "%"}</td>
                        <td>
                          {calculateCompletionPercentage(
                            details.start_time,
                            details.end_time
                          ) + "%"}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  )
}

export default TerraClassicEscrows
